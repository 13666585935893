<template>
  <div class="bg-login d-flex align-center justify-center">
    <div class="bg-box d-flex flex-column align-center justify-center">
      <v-card class="bg-card mx-auto px-6 py-8">
        <v-row align="center" class="mb-2">
          <v-col>
            <p class="text-title">エラー</p>
            <p class="text-message mt-5">{{ message }}</p>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {eventBus} from "@/helpers/eventBus";
import CryptoJS from 'crypto-js'

export default {
  name: 'ErrorView',
  setup() {
    const route = useRoute();
    const message = ref(route.params.message);

    onMounted(() => {
      message.value = decryptText(message.value);
      eventBus.emit('v-drawer-hide');
    });

    const decryptText = (message) => {
      const key = "aQwdesspq";
      const bytes = CryptoJS.AES.decrypt(message, key);
      return bytes.toString(CryptoJS.enc.Utf8);
    }

    return {
      message
    }
  }
}
</script>

<style>
.bg-login {
  background: #E7D8CD;
  height: 100vh;
  width: 100%;
}

.bg-card {
  width: 100%;
}

.bg-box {
  width: 28%;
}

@media (max-width: 600px) {
  .bg-box {
    width: 80%
  }
}

.text-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.text-message {
  text-align: center;
  font-size: 16px;
  font-style: normal;
}
</style>
