import {PAGE} from '@/helpers/data-value-common'
import {createRouter, createWebHistory} from 'vue-router';
import Dashboard from '@/views/dashboard/Index.vue'
import Users from '@/views/user/Index.vue'
import UserDetail from '@/views/user/Detail.vue'
import Patients from '@/views/patient/Index.vue'
import PatientDetail from '@/views/patient/Detail.vue'
import PatientView from '@/views/patient/View.vue'
import SharedLinks from '@/views/sharedlink/Index.vue'
import SharedLinkDetail from '@/views/sharedlink/Detail.vue'
import Error from '@/views/Error.vue'
import Login from '@/views/auth/Login.vue'
import Forgot from '@/views/auth/Forgot.vue'
import store from '@/store';
import Index from "@/views/Index.vue";

const routes = [
  {
    path: PAGE.REDIRECT.PATH,
    name: PAGE.REDIRECT.NAME,
    component: Index,
  },
  {
    path: PAGE.ERROR.PATH,
    name: PAGE.ERROR.NAME,
    component: Error
  },
  {
    path: PAGE.DASHBOARD.PATH,
    name: PAGE.DASHBOARD.NAME,
    component: Dashboard,
    meta: {requiresAuth: true}
  },
  {
    path: PAGE.USERS.PATH,
    name: PAGE.USERS.NAME,
    component: Users,
    meta: {requiresAuth: true}
  },
  {
    path: PAGE.USER_DETAIL.PATH,
    name: PAGE.USER_DETAIL.NAME,
    component: UserDetail,
    meta: {requiresAuth: true}
  },
  {
    path: PAGE.PATIENTS.PATH,
    name: PAGE.PATIENTS.NAME,
    component: Patients,
    meta: {requiresAuth: true}
  },
  {
    path: PAGE.PATIENT_VIEW.PATH,
    name: PAGE.PATIENT_VIEW.NAME,
    component: PatientView,
    meta: {requiresAuth: false}
  },
  {
    path: PAGE.PATIENT_DETAIL.PATH,
    name: PAGE.PATIENT_DETAIL.NAME,
    component: PatientDetail,
    meta: {requiresAuth: true}
  },
  {
    path: PAGE.SHARED_LINKS.PATH,
    name: PAGE.SHARED_LINKS.NAME,
    component: SharedLinks,
    meta: {requiresAuth: true}
  },
  {
    path: PAGE.SHARED_LINK_DETAIL.PATH,
    name: PAGE.SHARED_LINK_DETAIL.NAME,
    component: SharedLinkDetail,
    meta: {requiresAuth: true}
  },
  {
    path: PAGE.LOGIN.PATH,
    name: PAGE.LOGIN.NAME,
    component: Login,
  },
  {
    path: PAGE.FORGOT.PATH,
    name: PAGE.FORGOT.NAME,
    component: Forgot
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['getId'] === null) {
      next({path: PAGE.LOGIN.PATH});
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
