<template>
  <div class="bg-login d-flex align-center justify-center">
    <div class="bg-box d-flex flex-column align-center justify-center">
      <v-card class="bg-card mx-auto px-6 py-8">
        <v-form @submit.prevent="submit">
          <v-row align="center" class="mb-5">
            <v-col cols="auto">
              <v-icon @click="clickedBack">mdi-arrow-left</v-icon>
            </v-col>
            <v-col>
              <p class="text-center" style="margin-left: -30px">パスワードリセット</p>
            </v-col>
          </v-row>
          <v-text-field
              v-model="data.email"
              :clearable=true
              :flat=true
              bg-color="#E1D8D4"
              label="Email"
              rounded="xl"
              variant="solo-filled"
          ></v-text-field>

          <div class="text-center mb-4">
            <p class="text-caption text-red">{{ error }}</p>
          </div>

          <v-btn
              :block=true
              color="#00A4E3"
              rounded="xl"
              size="large"
              type="submit"
              variant="elevated"
          >
            送信
          </v-btn>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import {PAGE} from '@/helpers/data-value-common';
import {onMounted, reactive, ref} from "vue";
import {useRouter} from "vue-router";
import {eventBus} from "@/helpers/eventBus";
import axios from "axios";
import {extractErrorMessage} from "@/helpers/errorHandler";

export default {
  name: 'ForgotView',
  setup() {
    const data = reactive({
      email: ''
    });
    const error = ref('')
    const router = useRouter();

    const clickedBack = () => {
      router.push(PAGE.LOGIN.PATH);
    }

    const isValidEmail = (email) => {
      return /\S+@\S+\.\S+/.test(email);
    };

    const submit = async () => {
      if (!isValidEmail(data.email)) {
        error.value = "有効なメールアドレスを入力してください";
        return;
      }

      await axios.post('auth/forgot', data, {
        withCredentials: true
      }).then(() => {
        router.push(PAGE.LOGIN.PATH);
      }).catch(errors => {
        console.log(errors)
        error.value = extractErrorMessage(errors);
      });
    }

    onMounted(() => {
      eventBus.emit('v-drawer-hide');
    });

    return {
      data,
      error,
      clickedBack,
      submit,
    }
  }
}
</script>

<style>
.bg-login {
  background: #E7D8CD;
  height: 100vh;
  width: 100%;
}

.bg-card {
  background: #EFE6E1;
  width: 100%;
}

.bg-box {
  width: 28%;
}

@media (max-width: 600px) {
  .bg-box {
    width: 80%
  }
}

.text-center {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
</style>
