<template>
  <v-dialog v-model="showDialog" width="500">
    <v-card class="pa-3">
      <v-card-title class="d-flex align-items-center justify-space-between">
        <span class="title-sp flex-grow-1">連絡を選択してください</span>
        <v-icon @click="closeDialog">mdi-close</v-icon>
      </v-card-title>

      <v-card-text>
        <v-row class="ma-0">
          <v-col class="pa-1 d-flex align-center" cols="10">
            <v-text-field
                v-model="searchName"
                :clearable=true
                dense="dense"
                hide-details
                label="名前"
                variant="outlined">
            </v-text-field>
          </v-col>
          <v-col class="pa-1 d-flex align-center" cols="1">
            <v-btn class="mx-1 base-btn base-btn-search elevation-0 d-flex align-stretch w-100"
                   @click="loadPatients">検索
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
            v-model:on-update:page="paginationCurrent"
            :headers="headers"
            :items="data"
            :items-per-page="10"
            class="my-4"
            @click:row="selectItem"
        >
          <template #bottom></template>
        </v-data-table>
        <v-row class="ma-0">
          <v-col class="pa-0 d-flex align-center justify-end" cols="2">
          </v-col>
          <v-col class="pa-0 d-flex align-center justify-end" cols="8">
            <v-pagination
                v-model="paginationCurrent"
                :length="paginationCount"
                :total-visible="7"
                color="#3C71AC"
                @update:model-value="changePagination">
            </v-pagination>
            Page:
          </v-col>
          <v-col class="pa-0 d-flex align-center justify-end" cols="2">
            <v-text-field
                id="pageNumberTextField"
                v-model="pageNumber"
                class="ml-2 page-txt-field"
                dense
                hide-details
                outline
                type="number"
                @keydown.enter="onPressPagingKeyEnter"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {onMounted, ref, watch} from 'vue';
import axios from "axios";
import {extractErrorMessage} from "@/helpers/errorHandler";
import {PAGE} from "@/helpers/data-value-common";
import {useRouter} from "vue-router";
import {useStore} from "vuex";

export default {
  props: {
    modelValue: Boolean,
  },
  emits: ['update:modelValue', 'selected'],
  setup(props, {emit}) {
    const router = useRouter();
    const store = useStore();

    let errorMessage = ref('');
    const searchName = ref('');
    const headers = ref([
      {title: 'ID', key: 'id', width: '10%'},
      {title: '名前', key: 'name', width: '40%'},
      {title: 'メールアドレス', key: 'email', width: '50%'}
    ]);
    let pageNumber = ref(1);
    let paginationCurrent = ref(1);
    let paginationCount = ref(0);
    const data = ref([]);

    const showDialog = ref(props.modelValue);

    const loadPatients = async () => {
      const params = {
        page: paginationCurrent.value,
        per_page: 10,
        name: searchName.value,
        sort: "created_at asc",
        is_deleted: false,
      }

      await axios
      .get('rms/patients', {params, withCredentials: true})
      .then(response => {
        data.value = response.data.data;
        paginationCurrent.value = response.data.page;
        paginationCount.value = response.data.last_page;
      })
      .catch(errors => {
        const {message, statusCode} = extractErrorMessage(errors);
        errorMessage = message;

        if (statusCode === 403) {
          router.push(PAGE.LOGIN.PATH);
          store.dispatch('setId', null);
        }
      })
    }

    watch(() => props.modelValue, (newVal) => {
      showDialog.value = newVal;
    });

    const changePagination = (number) => {
      paginationCurrent.value = number;
      pageNumber.value = number;
      loadPatients()
    }

    const onPressPagingKeyEnter = () => {
      let input = document.getElementById("pageNumberTextField");
      let val = parseInt(input.value)
      if (val > 0 && val <= paginationCount.value) {
        paginationCurrent.value = val;
        loadPatients();
      }
      pageNumber.value = paginationCurrent.value
    }

    const closeDialog = () => {
      emit('update:modelValue', false);
    };

    const selectItem = (event, item) => {
      emit('selected', item.item);
      closeDialog();
    };

    onMounted(async () => {
      await loadPatients();
    })

    return {
      showDialog,
      searchName,
      headers,
      data,
      pageNumber,
      paginationCurrent,
      paginationCount,
      errorMessage,
      closeDialog,
      selectItem,
      loadPatients,
      changePagination,
      onPressPagingKeyEnter,
    };
  },
};
</script>


<style lang="scss" scoped>
.title-sp {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}

.base-btn {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;

  &-search {
    color: var(--color-white) !important;
    background-color: var(--color-green) !important;
    border: 1px solid var(--color-green) !important;
  }
}
</style>
