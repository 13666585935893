<template>
  <div class="bg-login d-flex align-center justify-center">
    <div class="bg-box d-flex flex-column align-center justify-center">
      <v-img
          class="mb-3"
          height="70"
          src="@/assets/images/healthcare-icon.png"
          width="70">
      </v-img>

      <h2 class="font-weight-bold text-center mb-5">Healthcare-Ecosystem</h2>

      <v-card class="bg-card mx-auto px-6 py-8">
        <v-form action="#" @submit.prevent="submit">
          <v-text-field
              v-model="data.email"
              :clearable=true
              :flat=true
              bg-color="#E1D8D4"
              label="Email"
              rounded="xl"
              variant="solo-filled"
          ></v-text-field>

          <v-text-field
              v-model="data.password"
              :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
              :clearable=true
              :flat=true
              :type="visible ? 'text' : 'password'"
              autocomplete="email"
              bg-color="#E1D8D4"
              label="Password"
              name="email"
              rounded="xl"
              variant="solo-filled"
              @click:append-inner="visible = !visible"
          ></v-text-field>

          <div class="text-center mb-4">
            <p class="text-caption text-red">{{ error }}</p>
          </div>

          <v-btn
              :block=true
              autocomplete="current-password"
              color="#00A4E3"
              name="password"
              rounded="xl"
              size="large"
              type="submit"
              variant="elevated"
          >
            ログイン
          </v-btn>
        </v-form>
      </v-card>
    </div>
    <v-loader :show-default="false"></v-loader>
  </div>
</template>
<script>

import {PAGE} from '@/helpers/data-value-common';
import {computed, onMounted, reactive, ref} from "vue";
import axios from "axios";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {extractErrorMessage} from "@/helpers/errorHandler";
import {eventBus} from "@/helpers/eventBus";
import VLoader from "@/components/common/VLoader.vue";

export default {
  name: 'LoginView',
  components: {
    'v-loader': VLoader
  },
  setup() {
    const data = reactive({
      email: '',
      password: '',
    });
    const error = ref('')
    const visible = ref(false)
    const router = useRouter();
    const store = useStore();

    const idUser = computed(() => store.getters.getId);

    const isValidEmail = (email) => {
      return /\S+@\S+\.\S+/.test(email);
    };

    const submit = async () => {
      if (!isValidEmail(data.email)) {
        error.value = "有効なメールアドレスを入力してください";
        return;
      }

      eventBus.emit('loader-show');
      try {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");

        const tokenResponse = await axios.post('rms/auth/login', data, {withCredentials: true})

        localStorage.setItem("accessToken", tokenResponse.data.accessToken);
        localStorage.setItem("refreshToken", tokenResponse.data.refreshToken);

        const userResponse = await axios.get("rms/user")
        store.dispatch('setId', userResponse.data.id);
        store.dispatch('setName', userResponse.data.name);
        store.dispatch('setRole', userResponse.data.role);
        router.push(PAGE.BASE.PATH);
      } catch (errors) {
        const {message} = extractErrorMessage(errors);
        error.value = message
        store.dispatch('setId', null);
        store.dispatch('setName', null);
        store.dispatch('setRole', null);
      } finally {
        eventBus.emit('loader-hide');
      }
    }

    onMounted(() => {
      eventBus.emit('v-drawer-hide');

      if (idUser.value != null && idUser.value !== 0) {
        router.push(PAGE.BASE.PATH);
      }
    });

    return {
      data,
      error,
      visible,
      submit
    }
  }
}
</script>

<style>
.bg-login {
  background: #E7D8CD;
  height: 100vh;
  width: 100%;
}

.bg-card {
  background: #EFE6E1;
  width: 100%;
}

.bg-box {
  width: 28%;
}

@media (max-width: 600px) {
  .bg-box {
    width: 80%
  }
}

.text-center {
  text-align: center;
}

.text-link {
  color: #696969;
}
</style>
