<template>
  <v-dialog v-model="showDialog" width="500">
    <v-card>
      <img :src="goodIcon" class="ma-5" height="50"/>
      <v-card-title class="text-center">メッセージ</v-card-title>
      <v-card-text class="text-center">
        <span class="message pb-5">{{ messages }}</span>
      </v-card-text>
      <v-btn @click="closeDialog">Ok</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>

import {ref, watch} from "vue";
import goodIcon from '@/assets/icons/ic_good.svg';

export default {
  name: 'VDialogConfirmation',
  props: {
    modelValue: Boolean,
    message: {
      type: String,
      default: '操作は完了しました'
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const showDialog = ref(props.modelValue);

    watch(() => props.modelValue, (newVal) => {
      showDialog.value = newVal;
    });

    const closeDialog = () => {
      emit('update:modelValue', false);
    };

    return {
      showDialog,
      closeDialog,
      goodIcon,
      messages: props.message
    }
  }
};
</script>
<style lang="scss" scoped>
.text-center {
  text-align: center;
}

.message {
  color: #000;
  font-weight: 300;
}
</style>
