import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from '@/store/index'
import '@/interceptors/axios'
import {loadFonts} from './plugins/webfontloader'
import VueApexCharts from 'vue3-apexcharts'

loadFonts()

createApp(App)
.use(router)
.use(vuetify)
.use(store)
.use(VueApexCharts)
.mount('#app')
