export function formatDateTime(isoString) {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function formatDate(isoString) {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function convertToDate(dateString) {
  return new Date(dateString);
}

export function convertToDateWithOutTimeZone(dateString) {
  return new Date(dateString + "T00:00");
}

/**
 * Get object parsed from json.
 * @param {String} json.
 * @returns {Object} Return object parsed.
 */
export function fromJSON(json) {
  return JSON.parse(json);
}

/**
 * Get json parsed from obj.
 * @param {Object} obj.
 * @returns {string} Return json string parsed.
 */
export function toJSON(obj) {
  return JSON.stringify(obj);
}
