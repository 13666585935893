<template>
  <v-dialog v-model="showDialog" width="500">
    <v-card>
      <div style="margin: 0 auto;">
        <img :src="errorIcon" class="ma-5" height="50" width="50"/>
      </div>
      <v-card-title class="text-center">エラー</v-card-title>
      <v-card-text class="text-center">
        <span class="error-message pb-5">{{ messageDialog }}</span>
      </v-card-text>
      <v-btn @click="closeDialog">Ok</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>

import {ref, watch} from "vue";
import errorIcon from '@/assets/icons/ic_error.png';

export default {
  name: 'VDialogError',
  props: {
    modelValue: Boolean,
    errorMessage: String,
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const showDialog = ref(props.modelValue);
    const messageDialog = ref(props.errorMessage);

    watch(() => props.modelValue, (newVal) => {
      showDialog.value = newVal;
    });

    watch(() => props.errorMessage, (newVal) => {
      messageDialog.value = newVal;
    });

    const closeDialog = () => {
      emit('update:modelValue', false);
    };

    return {
      messageDialog,
      showDialog,
      closeDialog,
      errorIcon
    }
  }
};
</script>
<style lang="scss" scoped>
.text-center {
  text-align: center;
}

.error-message {
  color: #000;
  font-weight: 300;
}
</style>
