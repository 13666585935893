<template>
  <div class="pa-5 fill-height">
    <v-card height="100%">
      <v-card-title>
        <v-row>
          <v-col class="d-flex align-center" cols="12" lg="10" md="9" sm="8">
      <span class="title-txt" @click="onClickBack">
        <v-icon left>mdi-chevron-left</v-icon>
        シェアリンク作成
      </span>
          </v-col>
          <v-col class="d-flex align-center justify-end" cols="12" lg="2" md="3" sm="4">
            <v-btn v-if="shareLinkId" class="base-btn-delete-user elevation-0 mr-2"
                   @click="deleteShareLink">削除
            </v-btn>
            <v-btn class="base-btn-create-user elevation-0" @click="saveShareLink">保存</v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider/>

      <v-card-text>
        <v-row class="mx-5 mb-2 mt-2">
          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">患者：</span>
              </v-col>
              <v-col cols="6">
                <div class="clickable-text-field" @click="showDialogPatient = true">
                  <v-text-field
                      v-model="patientName"
                      dense
                      hide-details
                      readonly
                      variant="outlined"
                  >
                  </v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
          </v-col>
        </v-row>
        <v-row class="mx-5 mb-5">
          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">有効期限：</span>
              </v-col>
              <v-col cols="6">
                <v-menu v-model="menuFrom" :close-on-content-click="false">
                  <template v-slot:activator="{ props }">
                    <v-text-field
                        :model-value="formattedDateFrom"
                        dense
                        hide-details
                        label="開始日"
                        readonly
                        v-bind="props"
                        variant="outlined"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateFrom" hide-details
                                 @update:model-value="menuFrom=false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="2">
                <span class="detail-txt">〜</span>
              </v-col>
              <v-col cols="6">
                <v-menu v-model="menuTo" :close-on-content-click="false">
                  <template v-slot:activator="{ props }">
                    <v-text-field
                        :model-value="formattedDateTo"
                        dense
                        hide-details
                        label="終了日"
                        readonly
                        v-bind="props"
                        variant="outlined"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateTo" hide-details
                                 @update:model-value="menuTo=false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div v-if="shareLink !== ''" class="alert-container mx-5 mb-2 mt-2 pa-5">
          <v-row class="my-4" justify="center">
            <v-col class="text-center" cols="12" md="6" sm="8">
              <h2 class="headline mb-2">リンク</h2>
              <div class="subheading mb-2">シェアリンクが生成されました</div>
              <div class="text">
                {{ shareLink }}
                <v-icon class="ml-2" small @click="copyToClipboard">mdi-content-copy</v-icon>
              </div>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col class="text-center ma-0" cols="12" md="6" sm="8">
              <v-row align="center">
                <v-col cols="3">
                  <div class="subheading">メールアドレス:</div>
                </v-col>
                <v-col cols="7" md="6" sm="8">
                  <v-text-field
                      v-model="email"
                      class="text-field-share align-center"
                      clearable
                      dense
                      hide-details
                      variant="outlined"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn class="white--text" color="primary" @click="sendMail">送信</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog-patient
        v-model.sync="showDialogPatient"
        @selected="handleSelectedPatient"/>
    <v-dialog-error
        v-model.sync="showDialogError"
        :error-message="errorMessage"
    />
    <v-dialog-confirmation
        v-model.sync="showDialogConfirmation"
    />
    <v-loader :show-default="false"></v-loader>
  </div>
</template>

<script>
import {MENU_CODE, PAGE} from "@/helpers/data-value-common";
import {useRoute, useRouter} from "vue-router";
import VDialogPatient from "@/components/layout/VDialogPatient.vue";
import {convertToDate} from "@/helpers/utils";
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {extractErrorMessage} from "@/helpers/errorHandler";
import VLoader from "@/components/common/VLoader.vue";
import {eventBus} from "@/helpers/eventBus";
import VDialogError from "@/components/layout/VDialogError.vue";
import VDialogConfirmation from "@/components/layout/VDialogConfirmation.vue";

export default {
  name: "ShareLinkDetail",
  components: {
    VDialogConfirmation,
    'v-loader': VLoader,
    'v-dialog-patient': VDialogPatient,
    'v-dialog-error': VDialogError,
    'v-dialog-confirmation': VDialogConfirmation,
  },
  setup() {
    let errorMessage = ref('');
    const showDialogError = ref(false);

    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const showDialogPatient = ref(false);
    const showDialogConfirmation = ref(false);
    const menuFrom = ref(false);
    const menuTo = ref(false);
    const dateFrom = ref(new Date());
    const dateTo = ref(new Date());
    const shareLinkId = ref(route.params.id);
    const patientId = ref(route.params.patientId);
    const patientName = ref(route.params.patientName);
    const shareLink = ref('')
    const email = ref('')

    const formattedDateTimeFrom = computed(() => {
      return formatDateTime(dateFrom.value);
    });

    const formattedDateTimeTo = computed(() => {
      return formatDateTime(dateTo.value);
    });

    const formattedDateFrom = computed(() => {
      return formatDate(dateFrom.value);
    });

    const formattedDateTo = computed(() => {
      return formatDate(dateTo.value);
    });

    const formattedDateFromWithSlash = computed(() => {
      return formatDateSlash(dateFrom.value);
    });

    const formattedDateToWithSlash = computed(() => {
      return formatDateSlash(dateTo.value);
    });

    function formatDateTime(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    function formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    function formatDateSlash(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}/${month}/${day}`;
    }

    const loadShareLink = async (id) => {
      eventBus.emit('loader-show');
      await axios
      .get(`rms/share-link/${id}`, {withCredentials: true})
      .then(response => {
        patientId.value = response.data.patient.id;
        patientName.value = response.data.patient.name;
        dateFrom.value = convertToDate(response.data.valid_start);
        dateTo.value = convertToDate(response.data.valid_end);
        shareLink.value = `${window.location.protocol}//${window.location.host}/${PAGE.PATIENT_VIEW.ABSOLUTE}/${response.data.code}`;
      })
      .catch(errors => {
        const {message, statusCode} = extractErrorMessage(errors);
        errorMessage.value = message;

        if (statusCode === 401) {
          router.push(PAGE.LOGIN.PATH);
          store.dispatch('setId', null);
          store.dispatch('setName', null);
          store.dispatch('setRole', null);
        } else {
          showDialogError.value = true
        }
      })
      .finally(() => {
        eventBus.emit('loader-hide');
      })
    }

    const saveShareLink = async () => {
      dateFrom.value.setHours(0, 0, 0, 0);
      dateTo.value.setHours(23, 59, 59, 0);

      const data = {
        patient_id: patientId.value,
        valid_start: formattedDateTimeFrom.value,
        valid_end: formattedDateTimeTo.value
      }

      eventBus.emit('loader-show');
      if (shareLinkId.value) {
        data.id = parseInt(shareLinkId.value);
        await axios
        .put('rms/share-link', data, {withCredentials: true})
        .then((response) => {
          shareLink.value = `${window.location.protocol}//${window.location.host}/${PAGE.PATIENT_VIEW.ABSOLUTE}/${response.data.share.code}`;
          showDialogConfirmation.value = true
        })
        .catch(errors => {
          const {message, statusCode} = extractErrorMessage(errors);
          errorMessage.value = message;

          if (statusCode === 401) {
            router.push(PAGE.LOGIN.PATH);
            store.dispatch('setId', null);
            store.dispatch('setName', null);
            store.dispatch('setRole', null);
          } else {
            showDialogError.value = true
          }
        })
        .finally(() => {
          eventBus.emit('loader-hide');
        })
      } else {
        await axios
        .post('rms/share-links', data, {withCredentials: true})
        .then((response) => {
          shareLinkId.value = response.data.share.id;
          shareLink.value = `${window.location.protocol}//${window.location.host}/${PAGE.PATIENT_VIEW.ABSOLUTE}/${response.data.share.code}`;
          showDialogConfirmation.value = true
        })
        .catch(errors => {
          const {message, statusCode} = extractErrorMessage(errors);
          errorMessage.value = message;

          if (statusCode === 401) {
            router.push(PAGE.LOGIN.PATH);
            store.dispatch('setId', null);
            store.dispatch('setName', null);
            store.dispatch('setRole', null);
          } else {
            showDialogError.value = true
          }
        })
        .finally(() => {
          eventBus.emit('loader-hide');
        })
      }
    }

    const deleteShareLink = async () => {
      eventBus.emit('loader-show');
      await axios
      .delete(`rms/share-link/${shareLinkId.value}`, {withCredentials: true})
      .then(() => {
        router.push(PAGE.SHARED_LINKS.PATH);
      })
      .catch(errors => {
        const {message, statusCode} = extractErrorMessage(errors);
        errorMessage.value = message;

        if (statusCode === 401) {
          router.push(PAGE.LOGIN.PATH);
          store.dispatch('setId', null);
          store.dispatch('setName', null);
          store.dispatch('setRole', null);
        } else {
          showDialogError.value = true
        }
      })
      .finally(() => {
        eventBus.emit('loader-hide');
      })
    }

    const onClickBack = () => {
      router.push(PAGE.SHARED_LINKS.PATH);
    }

    const copyToClipboard = async () => {
      if (navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(shareLink.value);
        } catch (err) {
          errorMessage.value = err
          showDialogError.value = true
        }
      }
    }

    const sendMail = async () => {
      const data = {
        to: email.value,
        subject: "Healthcare Ecosytem シェアリンク",
        patient_name: patientName.value,
        valid_start: formattedDateFromWithSlash.value,
        valid_end: formattedDateToWithSlash.value,
        link: shareLink.value,
      }

      eventBus.emit('loader-show');
      await axios
      .post('rms/share-links/send', data, {withCredentials: true})
      .then(() => {
        showDialogConfirmation.value = true
      })
      .catch(errors => {
        const {message, statusCode} = extractErrorMessage(errors);
        errorMessage.value = message;

        if (statusCode === 401) {
          router.push(PAGE.LOGIN.PATH);
          store.dispatch('setId', null);
          store.dispatch('setName', null);
          store.dispatch('setRole', null);
        } else {
          showDialogError.value = true
        }
      })
      .finally(() => {
        eventBus.emit('loader-hide');
      })
    }

    const handleSelectedPatient = (item) => {
      patientName.value = item.name
      patientId.value = item.id
    };

    onMounted(async () => {
      eventBus.emit('v-drawer-active-item', MENU_CODE.SHARED_LINKS);
      if (shareLinkId.value) {
        await loadShareLink(shareLinkId.value);
      }
    });

    return {
      patientId,
      patientName,
      shareLink,
      showDialogPatient,
      showDialogConfirmation,
      email,
      menuFrom,
      menuTo,
      dateFrom,
      dateTo,
      formattedDateFrom,
      formattedDateTo,
      formattedDateTimeFrom,
      formattedDateTimeTo,
      shareLinkId,
      errorMessage,
      showDialogError,
      onClickBack,
      saveShareLink,
      deleteShareLink,
      copyToClipboard,
      sendMail,
      handleSelectedPatient,
    }
  }
}

</script>

<style lang="scss" scoped>
.title-txt {
  font-family: var(--font-secondary);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--color-primary-dark);
}

.detail-txt {
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: var(--color-black);
}

.subtitle-txt {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: 700;
}

.alert-container {
  background: #EBEFF6;
}

.headline {
  font-size: 24px;
  font-weight: bold;
  color: var(--color-primary);
}

.subheading {
  font-size: 16px;
  font-weight: bold;
  color: var(--color-black);
}

.text {
  font-size: 14px;
  font-weight: normal;
  color: var(--color-black);
}

.text-field-share {
  background: #FFFFFF;
}

.clickable-text-field {
  cursor: pointer;
}

.base-btn {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;

  &-create-user {
    color: var(--color-white) !important;
    background-color: var(--color-primary-dark) !important;
    border: 1px solid var(--color-primary-dark) !important;
  }

  &-delete-user {
    color: var(--color-white) !important;
    background-color: var(--color-red) !important;
    border: 1px solid var(--color-red) !important;
  }
}
</style>
