<template>
  <div class="fill-height pa-10">
    <v-card height="100%">
      <v-card-title class="pa-7">
        <v-row>
          <v-col class="d-flex align-center" cols="12" md="10" sm="8">
            <span class="title-txt">患者</span>
          </v-col>
          <v-col class="d-flex align-center justify-end" cols="12" md="2"
                 sm="4" @click="clickedSynchronize">
            <v-btn class="mx-1 base-btn-create-user elevation-0">同期</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="ma-0">
          <v-col class="pa-0" cols="12">
            <v-container fluid>
              <v-row class="ma-0">
                <v-col class="pa-1" cols="12" lg="7" md="7" sm="7">
                  <v-text-field
                      v-model="searchName"
                      :clearable=true
                      dense="dense"
                      hide-details
                      label="名前"
                      variant="outlined">
                  </v-text-field>
                </v-col>
                <v-col class="pa-1" cols="12" lg="4" md="4" sm="4">
                  <v-combobox
                      v-model="searchStatus"
                      :items="status"
                      hide-details
                      label="状態"
                      variant="outlined">
                  </v-combobox>
                </v-col>
                <v-col class="pa-1" cols="12" lg="1" md="1" sm="1">
                  <v-btn
                      class="mx-1 base-btn base-btn-search elevation-0 d-flex align-stretch w-100"
                      @click="loadPatients">検索
                  </v-btn>
                </v-col>
              </v-row>
              <v-data-table
                  v-model:on-update:page="paginationCurrent"
                  :headers="headers"
                  :items="data"
                  :items-per-page="10"
                  :must-sort="true"
                  class="my-4"
                  @click:row="onClickRow"
                  @update:sort-by="onSort('by', $event)"
                  @update:sort-desc="onSort('desc', $event)"
              >
                <template #bottom></template>

                <template v-slot:[`item.sync_flag`]="{ item }">
                  <div class="text-center">
                    <img :src="item.sync_flag ? syncIcon : notSyncIcon" height="20">
                  </div>
                </template>

                <template v-slot:[`item.is_deleted`]="{ item }">
                  <div
                      :class="['status-chip', item.is_deleted ? 'is-deleted-false' : 'is-deleted-true']">
                    <span style="color: #FFFFFF">{{ item.is_deleted ? '削除' : '有効' }}</span>
                  </div>
                </template>
              </v-data-table>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col class="pa-0 d-flex align-center justify-end" cols="3">
          </v-col>
          <v-col class="pa-0 d-flex align-center justify-end" cols="8">
            <v-pagination
                v-model="paginationCurrent"
                :length="paginationCount"
                :total-visible="7"
                color="#3C71AC"
                @update:model-value="changePagination">
            </v-pagination>
            Page:
          </v-col>
          <v-col class="pa-0 d-flex align-center justify-end" cols="1">
            <v-text-field
                id="pageNumberTextField"
                v-model="pageNumber"
                class="ml-2 page-txt-field"
                dense
                hide-details
                outline
                type="number"
                @keydown.enter="onPressPagingKeyEnter"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog-error
        v-model.sync="showDialogError"
        :error-message="errorMessage"
    />
    <v-dialog-confirmation
        v-model.sync="showDialogConfirmation"
        message="同期を完了しました。"
    />
    <v-loader :show-default="false"></v-loader>
  </div>
</template>

<script>

import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
import {MENU_CODE, PAGE} from "@/helpers/data-value-common";
import {eventBus} from "@/helpers/eventBus";
import {extractErrorMessage} from "@/helpers/errorHandler";
import {useRouter} from "vue-router";
import alertIcon from '@/assets/icons/ic_alert.svg';
import notAlertIcon from '@/assets/icons/ic_not_alert.svg';
import VLoader from "@/components/common/VLoader.vue";
import VDialogError from "@/components/layout/VDialogError.vue";
import VDialogConfirmation from "@/components/layout/VDialogConfirmation.vue";
import syncIcon from "@/assets/icons/ic_sync.svg"
import notSyncIcon from "@/assets/icons/ic_not_sync.svg"

export default {
  name: "Patients",
  components: {
    'v-loader': VLoader,
    'v-dialog-error': VDialogError,
    'v-dialog-confirmation': VDialogConfirmation,
  },
  setup() {
    const headers = ref([
      {title: 'ID', key: 'id', width: '10%'},
      {title: '名前', key: 'name', width: '20%'},
      {title: '生年月日', key: 'birthday', width: '20%'},
      {title: 'メールアドレス', key: 'email', width: '20%'},
      {title: '電話番号', key: 'mobile', width: '10%'},
      {title: 'VD受信', key: 'sync_flag', width: '10%', align: 'center'},
      {title: '状態', key: 'is_deleted', width: '10%', align: 'center'},
    ]);

    const status = ref(["すべて", "有効", "削除"]);

    let errorMessage = ref('');
    const showDialogError = ref(false);
    const showDialogConfirmation = ref(false);

    const router = useRouter();
    const store = useStore();
    const idUser = computed(() => store.state.id);

    const searchName = ref('');
    const searchEmail = ref('');
    const searchPhone = ref('');
    const searchStatus = ref('有効');

    let pageNumber = ref(1);
    let paginationCurrent = ref(1);
    let paginationCount = ref(0);
    let data = ref([]);

    const loadPatients = async (sortField = null) => {
      const params = {
        page: paginationCurrent.value,
        per_page: 10,
        name: searchName.value,
        sort: typeof sortField === 'string' ? sortField || "id asc" : "id asc"
      }

      if (searchStatus.value !== 'すべて') {
        params.is_deleted = searchStatus.value !== '有効';
      }

      eventBus.emit('loader-show');
      await axios
      .get('rms/patients', {params, withCredentials: true})
      .then(response => {
        data.value = response.data.data;
        paginationCurrent.value = response.data.page;
        paginationCount.value = response.data.last_page;
      })
      .catch(errors => {
        const {message, statusCode} = extractErrorMessage(errors);
        errorMessage.value = message;

        if (statusCode === 401) {
          router.push(PAGE.LOGIN.PATH);
          store.dispatch('setId', null);
          store.dispatch('setName', null);
          store.dispatch('setRole', null);
        } else {
          showDialogError.value = true
        }
      })
      .finally(() => {
        eventBus.emit('loader-hide');
      })
    }

    const changePagination = (number) => {
      paginationCurrent.value = number;
      pageNumber.value = number;
      loadPatients()
    }

    const onPressPagingKeyEnter = () => {
      let input = document.getElementById("pageNumberTextField");
      let val = parseInt(input.value)
      if (val > 0 && val <= paginationCount.value) {
        paginationCurrent.value = val;
        loadPatients();
      }
      pageNumber.value = paginationCurrent.value
    }

    const clickedSynchronize = async () => {
      // synchronize data
      eventBus.emit('loader-show');
      await axios
      .post('rms/patient/sync', data, {withCredentials: true})
      .then(() => {
        eventBus.emit('loader-hide');
        showDialogConfirmation.value = true;

        // fetch list data
        paginationCurrent.value = 1
        pageNumber.value = 1
        loadPatients()
      })
      .catch(errors => {
        const {message, statusCode} = extractErrorMessage(errors);
        errorMessage.value = message;

        if (statusCode === 401) {
          router.push(PAGE.LOGIN.PATH);
          store.dispatch('setId', null);
          store.dispatch('setName', null);
          store.dispatch('setRole', null);
        } else {
          showDialogError.value = true
        }
      })
      .finally(() => {
        eventBus.emit('loader-hide');
      })
    }

    const onClickRow = (event, item) => {
      router.push({
        name: PAGE.PATIENT_DETAIL.NAME,
        params: {
          id: item.item.id,
        }
      });
    }

    const onSort = (byDesc, event) => {
      if (event.length > 0) {
        loadPatients(event[0].key + ' ' + event[0].order);
      }
    };

    onMounted(async () => {
      eventBus.emit('v-drawer-active-item', MENU_CODE.PATIENTS);
      await loadPatients();
    })

    return {
      showDialogError,
      showDialogConfirmation,
      errorMessage,
      headers,
      status,
      searchName,
      searchEmail,
      searchPhone,
      searchStatus,
      pageNumber,
      paginationCurrent,
      paginationCount,
      data,
      alertIcon,
      notAlertIcon,
      syncIcon,
      notSyncIcon,
      idUser,
      loadPatients,
      clickedSynchronize,
      onClickRow,
      onSort,
      changePagination,
      onPressPagingKeyEnter,
    }
  }
}
</script>

<style lang="scss">
.title-txt {
  font-family: var(--font-secondary);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--color-primary-dark);
}

.base-btn {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;

  &-create-user {
    color: var(--color-white) !important;
    background-color: var(--color-primary-dark) !important;
    border: 1px solid var(--color-primary-dark) !important;
  }

  &-search {
    color: var(--color-white) !important;
    background-color: var(--color-green) !important;
    border: 1px solid var(--color-green) !important;
  }
}

.status-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: bold;
  max-width: 120px;
}

.alert-true {
  background-color: #EDFC43;
}

.alert-false {
  background-color: #BEBEBE;
}

.is-deleted-true {
  background-color: #4CAF50;
}

.is-deleted-false {
  background-color: #FF5252;
}

.status-chip img {
  height: 20px;
  margin-right: 5px;
}

.page-txt-field {
  font-size: 14px;
}

</style>
