<template>
  <div class="fill-height pa-10">
    <v-card height="100%">
      <v-card-title class="pa-7">
        <v-row>
          <v-col class="d-flex align-center" cols="10">
            <span class="title-txt">ダッシュボード</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="ma-0">
          <v-col class="pa-0" cols="12">
            <v-container fluid>
              <v-data-table
                  v-model:on-update:page="paginationCurrent"
                  :headers="headers"
                  :items="data"
                  :items-per-page="10"
                  :must-sort="true"
                  class="my-4"
                  @update:sort-by="onSort('by', $event)"
                  @update:sort-desc="onSort('desc', $event)"
              >

                <template #bottom></template>

                <template v-slot:[`item.patient_name`]="{ item }">
                  {{ item.patient.name }}
                </template>

                <template v-slot:[`item.alert_time`]="{ item }">
                  {{ formatDate(item.alert_time) }}
                </template>

                <template v-slot:[`item.patient_mobile`]="{ item }">
                  {{ item.patient.mobile }}
                </template>

                <template v-slot:[`item.indicators`]="{ item }">
                  <v-chip v-if="item.is_spo2" color="blue" text-color="white">
                    SpO2
                  </v-chip>
                  <v-chip v-if="item.is_heart_rate" color="green" text-color="white">
                    Heart Rate
                  </v-chip>
                  <v-chip v-if="item.is_long_time_no_data" color="red" text-color="white">
                    No Data
                  </v-chip>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn class="alert-action" color="blue" variant="outlined"
                         @click="showPatient(item)">
                    患者を見せる
                  </v-btn>
                </template>

              </v-data-table>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col class="pa-0 d-flex align-center justify-end" cols="3">
          </v-col>
          <v-col class="pa-0 d-flex align-center justify-end" cols="8">
            <v-pagination
                v-model="paginationCurrent"
                :length="paginationCount"
                :total-visible="7"
                color="#3C71AC"
                @update:model-value="changePagination">
            </v-pagination>
            Page:
          </v-col>
          <v-col class="pa-0 d-flex align-center justify-end" cols="1">
            <v-text-field
                id="pageNumberTextField"
                v-model="pageNumber"
                class="ml-2 page-txt-field"
                dense
                hide-details
                outline
                type="number"
                @keydown.enter="onPressPagingKeyEnter"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog-error
        v-model.sync="showDialogError"
        :error-message="errorMessage"
    />
    <v-loader :show-default="false"></v-loader>
  </div>
</template>

<script>

import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
import {MENU_CODE, PAGE} from "@/helpers/data-value-common";
import {eventBus} from "@/helpers/eventBus";
import {extractErrorMessage} from "@/helpers/errorHandler";
import {useRouter} from "vue-router";
import alertIcon from '@/assets/icons/ic_alert.svg';
import notAlertIcon from '@/assets/icons/ic_not_alert.svg';
import {formatDate} from "@/helpers/utils"
import VLoader from "@/components/common/VLoader.vue"
import VDialogError from "@/components/layout/VDialogError.vue";

export default {
  name: "Dashboard",
  methods: {formatDate},
  components: {
    'v-loader': VLoader,
    'v-dialog-error': VDialogError,
  },
  setup() {
    const headers = ref([
      {title: 'ID', key: 'id', width: '10%'},
      {title: '患者', key: 'patient_name', width: '20%'},
      {title: '携帯電話', key: 'patient_mobile', width: '20%', sortable: false},
      {title: '警報日', key: 'alert_time', width: '10%'},
      {title: '警告の種類', key: 'indicators', width: '20%'},
      {title: 'アクション', key: 'actions', width: '20%'},
    ]);

    const status = ref(["すべて", "有効", "削除"]);

    let errorMessage = ref('');
    const showDialogError = ref(false);

    const router = useRouter();
    const store = useStore();
    const idUser = computed(() => store.state.id);

    const searchName = ref('');
    const searchEmail = ref('');
    const searchPhone = ref('');
    const searchStatus = ref('すべて');

    let pageNumber = ref(1);
    let paginationCurrent = ref(1);
    let paginationCount = ref(0);
    let data = ref([]);

    const loadPatients = async (sortField = null) => {
      const params = {
        page: paginationCurrent.value,
        per_page: 10,
        sort: typeof sortField === 'string' ? sortField || "id asc" : "id asc"
      }

      eventBus.emit('loader-show');
      await axios
      .get('rms/alerts', {params, withCredentials: true})
      .then(response => {
        data.value = response.data.data;
        paginationCurrent.value = response.data.page;
        paginationCount.value = response.data.last_page;
      })
      .catch(errors => {
        const {message, statusCode} = extractErrorMessage(errors);
        errorMessage.value = message;

        if (statusCode === 401) {
          router.push(PAGE.LOGIN.PATH);
          store.dispatch('setId', null);
          store.dispatch('setName', null);
          store.dispatch('setRole', null);
        } else {
          showDialogError.value = true
        }
      })
      .finally(() => {
        eventBus.emit('loader-hide');
      })
    }

    const changePagination = (number) => {
      paginationCurrent.value = number;
      pageNumber.value = number;
      loadPatients()
    }

    const onPressPagingKeyEnter = () => {
      let input = document.getElementById("pageNumberTextField");
      let val = parseInt(input.value)
      if (val > 0 && val <= paginationCount.value) {
        paginationCurrent.value = val;
        loadPatients();
      }
      pageNumber.value = paginationCurrent.value
    }

    const showPatient = (item) => {
      router.push({
        name: PAGE.PATIENT_DETAIL.NAME,
        params: {id: item.patient.id}
      });
    }

    const onSort = (byDesc, event) => {
      if (event.length > 0) {
        loadPatients(event[0].key + ' ' + event[0].order);
      }
    };

    onMounted(async () => {
      eventBus.emit('v-drawer-active-item', MENU_CODE.DASHBOARD);
      await loadPatients();
    })

    return {
      showDialogError,
      errorMessage,
      headers,
      status,
      searchName,
      searchEmail,
      searchPhone,
      searchStatus,
      pageNumber,
      paginationCurrent,
      paginationCount,
      data,
      alertIcon,
      notAlertIcon,
      idUser,
      loadPatients,
      showPatient,
      onSort,
      changePagination,
      onPressPagingKeyEnter,
    }
  }
}
</script>

<style lang="scss">
.title-txt {
  font-family: var(--font-secondary);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--color-primary-dark);
}

.base-btn {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;

  &-create-user {
    color: var(--color-white) !important;
    background-color: var(--color-primary-dark) !important;
    border: 1px solid var(--color-primary-dark) !important;
  }

  &-search {
    color: var(--color-white) !important;
    background-color: var(--color-green) !important;
    border: 1px solid var(--color-green) !important;
  }
}

.status-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: bold;
  max-width: 120px;
}

.alert-true {
  background-color: #EDFC43;
}

.alert-false {
  background-color: #BEBEBE;
}

.is-deleted-true {
  background-color: #4CAF50;
}

.is-deleted-false {
  background-color: #FF5252;
}

.status-chip img {
  height: 20px;
  margin-right: 5px;
}

.patient-name-column {
  width: 300px;
}

.alert-action {
  border: 1px solid #C4C4C4;
  border-radius: 13px;
  color: var(--color-primary);
  text-transform: none;

  &:hover {
    background-color: rgba(0, 0, 255, 0.1);
  }
}

.page-txt-field {
  font-size: 14px;
}

</style>
