import axios from "axios";
import router from '../router';
import store from '../store'
import {PAGE} from "@/helpers/data-value-common";

axios.defaults.baseURL = '/v1/api/';

axios.defaults.headers.common['RMS-Time-Zone'] = 'Asia/Tokyo';

axios.interceptors.request.use(request => {

  if (["/rms/auth/login", "/rms/auth/refresh"].some(
      url => request.url.endsWith(url))) {
    return request
  }

  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return request;
}, error => {
  return Promise.reject(error);
});

axios.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const refreshToken = localStorage.getItem('refreshToken');
          if (!refreshToken) {
            throw new Error('No refresh token found');
          }

          const response = await axios.post('rms/auth/refresh', {
            token: refreshToken,
          });
          const {
            accessToken: newAccessToken,
            refreshToken: newRefreshToken
          } = response.data;

          localStorage.setItem('accessToken', newAccessToken);
          localStorage.setItem('refreshToken', newRefreshToken);
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

          return axios(originalRequest);
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');

          await store.dispatch('setId', null);
          await store.dispatch('setName', null);
          await store.dispatch('setRole', null);
          await router.push(PAGE.LOGIN.PATH);

          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
);
