<template>
  <div class="pa-5 fill-height">
    <v-card height="100%">
      <v-card-title>
        <v-row>
          <v-col class="d-flex align-center" cols="12" lg="10" md="9" sm="8">
            <span class="title-txt" @click="onClickBack">
              <v-icon v-if="roleUser === 'ADMIN'" left>mdi-chevron-left</v-icon>
              ユーザー作成
            </span>
          </v-col>
          <v-col class="d-flex align-center justify-end" cols="12" lg="2" md="3" sm="4">
            <v-btn v-if="userId && roleUser === 'ADMIN'"
                   class="base-btn-delete-user elevation-0 mr-2"
                   @click="deleteUser">削除
            </v-btn>
            <v-btn class="base-btn-create-user elevation-0" @click="saveUser">保存</v-btn>
          </v-col>
        </v-row>
      </v-card-title>


      <v-divider/>

      <v-card-text>
        <v-row class="mx-5 mb-0 mt-2">
          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">名前：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="name"
                    :clearable="true"
                    dense
                    hide-details
                    variant="outlined">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row v-if="roleUser === 'ADMIN'" align="center">
              <v-col cols="4">
                <span class="detail-txt">ロール：</span>
              </v-col>
              <v-col cols="6">
                <v-combobox
                    v-model="role"
                    :items="roles"
                    hide-details
                    variant="outlined">
                </v-combobox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-5 mb-0">
          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">メールアドレス：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="email"
                    :clearable="true"
                    dense
                    hide-details
                    variant="outlined">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">パスワード：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="password"
                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                    :clearable="true"
                    :type="visible ? 'text' : 'password'"
                    dense
                    hide-details
                    variant="outlined"
                    @click:append-inner="visible = !visible">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div class="alert-container mx-5 mb-2 mt-2 pa-5">
          <span class="subtitle-txt">アラート</span>
          <v-switch
              v-model="alertFlag"
              color="blue"
              label="アラートを受け取る"
          >
          </v-switch>

          <v-row class="mb-5">
            <v-col cols="12" md="6">
              <v-row align="center">
                <v-col cols="4">
                  <span class="detail-txt">メールアドレス：</span>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="alertEmail"
                      :clearable="true"
                      dense
                      hide-details
                      style="background: white"
                      variant="outlined">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog-error
        v-model.sync="showDialogError"
        :error-message="errorMessage"
    />
    <v-dialog-confirmation
        v-model.sync="showDialogConfirmation"
        message="保存しました。"
    />
    <v-loader :show-default="false"></v-loader>
  </div>
</template>

<script>
import {MENU_CODE, PAGE} from "@/helpers/data-value-common";
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {extractErrorMessage} from "@/helpers/errorHandler";
import {useStore} from "vuex";
import VLoader from "@/components/common/VLoader.vue";
import {eventBus} from "@/helpers/eventBus";
import VDialogError from "@/components/layout/VDialogError.vue";
import VDialogConfirmation from "@/components/layout/VDialogConfirmation.vue";

export default {
  name: "UserDetail",
  components: {
    'v-loader': VLoader,
    'v-dialog-error': VDialogError,
    'v-dialog-confirmation': VDialogConfirmation,
  },
  setup() {
    let errorMessage = ref('');
    const showDialogError = ref(false);
    const showDialogConfirmation = ref(false);

    const route = useRoute();
    const router = useRouter();
    const userId = ref(route.params.id);
    const store = useStore();
    const userIdLogged = computed(() => store.getters.getId);

    const roles = ref(["ADMIN", "DOCTOR"]);
    const visible = ref(false)

    const name = ref('');
    const role = ref('');
    const email = ref('');
    const password = ref('');
    const alertFlag = ref(false);
    const alertEmail = ref('');

    const roleUser = computed(() => store.state.role);

    const loadUser = async (id) => {
      eventBus.emit('loader-show');
      await axios
      .get(`rms/user/${id}`, {withCredentials: true})
      .then(response => {
        name.value = response.data.name;
        role.value = response.data.rol;
        email.value = response.data.email;
        alertFlag.value = response.data.alert_flag;
        alertEmail.value = response.data.alert_email;
      })
      .catch(errors => {
        const {message, statusCode} = extractErrorMessage(errors);
        errorMessage.value = message;

        if (statusCode === 401) {
          router.push(PAGE.LOGIN.PATH);
          store.dispatch('setId', null);
          store.dispatch('setName', null);
          store.dispatch('setRole', null);
        } else {
          showDialogError.value = true
        }
      })
      .finally(() => {
        eventBus.emit('loader-hide');
      });
    }

    const saveUser = async () => {
      const data = {
        name: name.value,
        is_deleted: false,
        email: email.value,
        alert_flag: alertFlag.value,
        alert_email: alertEmail.value,
      }

      if (password.value) {
        data.password = password.value;
      }

      if (roleUser.value === "ADMIN") {
        data.rol = role.value;
      }

      eventBus.emit('loader-show');
      if (userId.value) {
        data.id = userId.value;

        await axios
        .put('rms/user', data, {withCredentials: true})
        .then((response) => {
          if (parseInt(userId.value) === parseInt(userIdLogged.value)) {
            store.dispatch('setName', response.data.user.name);
            store.dispatch('setRole', response.data.user.rol);
          }

          showDialogConfirmation.value = true
        })
        .catch(errors => {
          const {message, statusCode} = extractErrorMessage(errors);
          errorMessage.value = message;
          if (statusCode === 401) {
            router.push(PAGE.LOGIN.PATH);
            store.dispatch('setId', null);
            store.dispatch('setName', null);
            store.dispatch('setRole', null);
          } else {
            showDialogError.value = true
          }
        })
        .finally(() => {
          eventBus.emit('loader-hide');
        });
      } else {
        await axios
        .post('rms/user', data, {withCredentials: true})
        .then(() => {
          showDialogConfirmation.value = true
        })
        .catch(errors => {
          const {message, statusCode} = extractErrorMessage(errors);
          errorMessage.value = message;
          if (statusCode === 401) {
            router.push(PAGE.LOGIN.PATH);
            store.dispatch('setId', null);
            store.dispatch('setName', null);
            store.dispatch('setRole', null);
          } else {
            showDialogError.value = true
          }
        })
        .finally(() => {
          eventBus.emit('loader-hide');
        });
      }
    }

    const deleteUser = async () => {
      eventBus.emit('loader-show');
      await axios
      .delete(`rms/user/${userId.value}`, {withCredentials: true})
      .then(() => {
        router.push(PAGE.USERS.PATH);
      })
      .catch(errors => {
        const {message, statusCode} = extractErrorMessage(errors);
        errorMessage.value = message;
        if (statusCode === 401) {
          router.push(PAGE.LOGIN.PATH);
          store.dispatch('setId', null);
          store.dispatch('setName', null);
          store.dispatch('setRole', null);
        } else {
          showDialogError.value = true
        }
      })
      .finally(() => {
        eventBus.emit('loader-hide');
      });
    }

    const onClickBack = () => {
      if (roleUser.value === "ADMIN") {
        router.push(PAGE.USERS.PATH);
      }
    }

    onMounted(async () => {
      eventBus.emit('v-drawer-active-item', MENU_CODE.USERS);
      if (userId.value) {
        await loadUser(userId.value);
      }
    });

    return {
      visible,
      roleUser,
      roles,
      name,
      role,
      email,
      password,
      alertFlag,
      alertEmail,
      userId,
      errorMessage,
      showDialogError,
      showDialogConfirmation,
      loadUser,
      saveUser,
      deleteUser,
      onClickBack
    }
  }
}

</script>

<style lang="scss" scoped>
.title-txt {
  font-family: var(--font-secondary);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--color-primary-dark);
}

.detail-txt {
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: var(--color-black);
}

.subtitle-txt {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: 700;
}

.alert-container {
  background: #EBEFF6;
}

.base-btn {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;

  &-create-user {
    color: var(--color-white) !important;
    background-color: var(--color-primary-dark) !important;
    border: 1px solid var(--color-primary-dark) !important;
  }

  &-delete-user {
    color: var(--color-white) !important;
    background-color: var(--color-red) !important;
    border: 1px solid var(--color-red) !important;
  }
}
</style>
