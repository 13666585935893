<template>
  <div class="pa-5 fill-height">
    <v-card height="100%">
      <v-card-title>
        <v-row>
          <v-col class="d-flex align-center" cols="12" lg="9" md="8" sm="7">
      <span class="title-txt" @click="onClickBack">
        <v-icon left>mdi-chevron-left</v-icon>
        患者作成
      </span>
          </v-col>
          <v-col v-if="patientId" class="d-flex align-center justify-end" cols="12" lg="3" md="4"
                 sm="5">
            <v-btn class="base-btn-share elevation-0 mr-2" prepend-icon="mdi-link"
                   @click="shareLinkPatient">シェア
            </v-btn>
            <v-btn class="base-btn-create elevation-0" @click="savePatient">保存</v-btn>
          </v-col>
          <v-col v-else class="d-flex align-center justify-end" cols="12" lg="3" md="4" sm="5">
            <v-btn class="base-btn-create elevation-0" @click="savePatient">保存</v-btn>
          </v-col>
        </v-row>
      </v-card-title>


      <v-divider/>

      <v-card-text>
        <v-row class="mx-5 mb-0 mt-2">
          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">名前：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="name"
                    dense
                    hide-details
                    readonly
                    variant="outlined">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">電話番号：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="phone"
                    :clearable="true"
                    dense
                    hide-details
                    type="number"
                    variant="outlined">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-5 mb-0">
          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">生年月日：</span>
              </v-col>
              <v-col cols="6">
                <!--                <v-menu v-model="menuBirthday" :close-on-content-click="false">-->
                <!--                  <template v-slot:activator="{ props }">-->
                <v-text-field
                    :model-value="formattedDateBirthday"
                    dense
                    hide-details
                    readonly
                    variant="outlined"
                ></v-text-field>
                <!--                  </template>-->
                <!--                  <v-date-picker v-model="dateBirthday" hide-details-->
                <!--                                 @update:model-value="menuBirthday=false"></v-date-picker>-->
                <!--                </v-menu>-->
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">携帯番号：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="mobile"
                    :clearable="true"
                    dense
                    hide-details
                    type="number"
                    variant="outlined">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-5 mb-0">
          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">性別：</span>
              </v-col>
              <v-col cols="6">
                <v-combobox
                    v-model="gender"
                    :items="genre"
                    hide-details
                    item-title="text"
                    item-value="value"
                    readonly
                    variant="outlined">
                </v-combobox>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">メールアドレス：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="email"
                    :clearable="true"
                    dense
                    hide-details
                    variant="outlined">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-5 mb-0">
          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">身長：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="height"
                    dense
                    hide-details
                    readonly
                    type="number"
                    variant="outlined">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">SpO2の最小値：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="minSpO2"
                    :clearable="true"
                    dense
                    hide-details
                    type="number"
                    variant="outlined">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-5 mb-0">
          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">体重：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="weight"
                    dense
                    hide-details
                    readonly
                    type="number"
                    variant="outlined">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">心拍数の最小値：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="minHeartRate"
                    :clearable="true"
                    dense
                    hide-details
                    type="number"
                    variant="outlined">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-5 mb-0">
          <v-col cols="12" md="6">
            <v-switch
                v-model="syncFlag"
                color="blue"
                label="バイタルデータを受信する"
            >
            </v-switch>
          </v-col>
          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">心拍数の最大値：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="maxHeartRate"
                    :clearable="true"
                    dense
                    hide-details
                    type="number"
                    variant="outlined">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div v-if="patientId" class="report-container mx-5 mb-0 pa-5">
          <span class="subtitle-txt">バイタルデータ</span>
          <v-row class="mt-5">
            <v-col cols="12" sm="2">
              <v-menu v-model="menuFrom" :close-on-content-click="false">
                <template v-slot:activator="{ props }">
                  <v-text-field
                      :model-value="formattedDateFrom"
                      dense
                      hide-details
                      label="開始日"
                      readonly
                      v-bind="props"
                      variant="outlined"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateFrom" hide-details
                               @update:model-value="menuFrom=false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="2">
              <v-menu v-model="menuTo" :close-on-content-click="false">
                <template v-slot:activator="{ props }">
                  <v-text-field
                      :model-value="formattedDateTo"
                      dense
                      hide-details
                      label="終了日"
                      readonly
                      v-bind="props"
                      variant="outlined"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateTo" hide-details
                               @update:model-value="menuTo=false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="d-flex align-center justify-center" cols="12" sm="1">
              <v-btn class="mx-5 base-btn-create-user elevation-0 small" @click="loadVitalData">
                検索
              </v-btn>
            </v-col>
          </v-row>
          <apex-chart :options="chartOptions" :series="series" height="350"
                      type="line"></apex-chart>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog-error
        v-model.sync="showDialogError"
        :error-message="errorMessage"
    />
    <v-dialog-confirmation
        v-model.sync="showDialogConfirmation"
        message="保存しました。"
    />
    <v-loader :show-default="false"></v-loader>
  </div>
</template>

<script>
import {MENU_CODE, PAGE} from "@/helpers/data-value-common";
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import {eventBus} from "@/helpers/eventBus";
import {useStore} from "vuex";
import axios from "axios";
import {extractErrorMessage} from "@/helpers/errorHandler";
import {convertToDateWithOutTimeZone} from "@/helpers/utils";
import VLoader from "@/components/common/VLoader.vue";
import VDialogError from "@/components/layout/VDialogError.vue";
import VueApexCharts from "vue3-apexcharts";
import VDialogConfirmation from "@/components/layout/VDialogConfirmation.vue";

export default {
  name: "PatientDetail",
  components: {
    'apex-chart': VueApexCharts,
    'v-loader': VLoader,
    'v-dialog-error': VDialogError,
    'v-dialog-confirmation': VDialogConfirmation,
  },
  setup() {
    let errorMessage = ref('');
    const showDialogError = ref(false);
    const showDialogConfirmation = ref(false);

    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const patientId = ref(route.params.id);

    const genre = ref([
      {text: '男性', value: '1'},
      {text: '女性', value: '2'}
    ]);

    const menuBirthday = ref(false);
    const menuFrom = ref(false);
    const menuTo = ref(false);
    const dateBirthday = ref(new Date());
    const dateFrom = ref(new Date());
    const dateTo = ref(new Date());
    const name = ref('');
    const birthday = ref('');
    const gender = ref('');
    const phone = ref('');
    const mobile = ref('');
    const height = ref('');
    const weight = ref('');
    const email = ref('');
    const minSpO2 = ref(0);
    const minHeartRate = ref(0);
    const maxHeartRate = ref(0);
    const syncFlag = ref(false);

    const series = ref([
      {name: 'SpO2', data: []},
      {name: 'Heart Rate', data: []},
      {name: 'Respiratory Rate', data: []},
    ]);

    const chartOptions = ref({
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      stroke: {
        width: [2, 2, 2],
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        }
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy HH:mm'
        }
      }
    });

    const loadPatient = async () => {
      eventBus.emit('loader-show');
      await axios
      .get(`rms/patient/${patientId.value}`, {withCredentials: true})
      .then(response => {
        name.value = response.data.name;
        birthday.value = response.data.birthday;
        dateBirthday.value = convertToDateWithOutTimeZone(response.data.birthday);
        gender.value = genre.value.find(g => g.value === response.data.gender);
        phone.value = response.data.phone;
        mobile.value = response.data.mobile;
        height.value = response.data.height;
        weight.value = response.data.weight;
        email.value = response.data.email;
        syncFlag.value = response.data.sync_flag;
        minSpO2.value = response.data.min_spo2;
        minHeartRate.value = response.data.min_heart_rate;
        maxHeartRate.value = response.data.max_heart_rate;
      })
      .catch(errors => {
        const {message, statusCode} = extractErrorMessage(errors);
        errorMessage.value = message;

        if (statusCode === 401) {
          router.push(PAGE.LOGIN.PATH);
          store.dispatch('setId', null);
          store.dispatch('setName', null);
          store.dispatch('setRole', null);
        } else {
          showDialogError.value = true
        }
      })
      .finally(() => {
        eventBus.emit('loader-hide');
      })
    }

    const loadVitalData = async (isFirstTime = false) => {
      const params = {
        from: formattedDateFrom.value
      }

      if (isFirstTime) {
        params.to = formattedDateTo.value
      }

      eventBus.emit('loader-show');
      await axios
      .get(`rms/patient/${patientId.value}/vital-data`, {params, withCredentials: true})
      .then(response => {
        processData(response.data);
      })
      .catch(errors => {
        const {message, statusCode} = extractErrorMessage(errors);
        errorMessage.value = message;

        if (statusCode === 401) {
          router.push(PAGE.LOGIN.PATH);
          store.dispatch('setId', null);
          store.dispatch('setName', null);
          store.dispatch('setRole', null);
        } else {
          showDialogError.value = true
        }
      })
      .finally(() => {
        eventBus.emit('loader-hide');
      })
    }

    const processData = (json) => {
      const rawData = json;
      const heartRateData = [];
      const spo2Data = [];
      const respiratoryRateData = [];

      rawData.forEach((item) => {
        const timestamp = new Date(item.timestamp).getTime();
        spo2Data.push([timestamp, item.spo2]);
        heartRateData.push([timestamp, item.heart_rate]);
        respiratoryRateData.push([timestamp, item.respiratory_rate]);
      });

      series.value[0].data = spo2Data;
      series.value[1].data = heartRateData;
      series.value[2].data = respiratoryRateData;
    };

    const formattedDateBirthday = computed(() => {
      return formatDate(dateBirthday.value);
    });

    const formattedDateFrom = computed(() => {
      return formatDate(dateFrom.value);
    });

    const formattedDateTo = computed(() => {
      return formatDate(dateTo.value);
    });

    function formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    const updateDateFrom = (event) => {
      dateFrom.value = event.target.value;
      menuFrom.value = false;
    }

    const onClickBack = () => {
      router.push(PAGE.PATIENTS.PATH);
    }

    const savePatient = async () => {
      const data = {
        phone: (phone.value === null || phone.value === undefined) ? "" : phone.value,
        mobile: (mobile.value === null || mobile.value === undefined) ? "" : mobile.value,
        email: (email.value === null || email.value === undefined) ? "" : email.value,
        sync_flag: syncFlag.value,
        min_spo2: (minSpO2.value === null || minSpO2.value === undefined) ? "" : minSpO2.value,
        min_heart_rate: (minHeartRate.value === null || minHeartRate.value === undefined) ? ""
            : minHeartRate.value,
        max_heart_rate: (maxHeartRate.value === null || maxHeartRate.value === undefined) ? ""
            : maxHeartRate.value,
        is_deleted: false
      }

      eventBus.emit('loader-show');
      if (patientId.value) {
        data.id = patientId.value;
        await axios
        .put('rms/patient', data, {withCredentials: true})
        .then(() => {
          showDialogConfirmation.value = true
        })
        .catch(errors => {
          const {message, statusCode} = extractErrorMessage(errors);
          errorMessage.value = message;
          if (statusCode === 401) {
            router.push(PAGE.LOGIN.PATH);
            store.dispatch('setId', null);
            store.dispatch('setName', null);
            store.dispatch('setRole', null);
          } else {
            showDialogError.value = true
          }
        })
        .finally(() => {
          eventBus.emit('loader-hide');
        })
      } else {
        await axios
        .post('rms/patient', data, {withCredentials: true})
        .then(() => {
          showDialogConfirmation.value = true
        })
        .catch(errors => {
          const {message, statusCode} = extractErrorMessage(errors);
          errorMessage.value = message;

          if (statusCode === 401) {
            router.push(PAGE.LOGIN.PATH);
            store.dispatch('setId', null);
            store.dispatch('setName', null);
            store.dispatch('setRole', null);
          } else {
            showDialogError.value = true
          }
        })
        .finally(() => {
          eventBus.emit('loader-hide');
        })
      }
    }

    const deletePatient = async () => {
      eventBus.emit('loader-show');
      await axios
      .delete(`rms/patient/${patientId.value}`, {withCredentials: true})
      .then(() => {
        router.push(PAGE.PATIENTS.PATH);
      })
      .catch(errors => {
        const {message, statusCode} = extractErrorMessage(errors);
        errorMessage.value = message;

        if (statusCode === 401) {
          router.push(PAGE.LOGIN.PATH);
          store.dispatch('setId', null);
          store.dispatch('setName', null);
          store.dispatch('setRole', null);
        } else {
          showDialogError.value = true
        }
      })
      .finally(() => {
        eventBus.emit('loader-hide');
      })
    }

    const shareLinkPatient = () => {
      router.push({
        name: PAGE.SHARED_LINK_DETAIL.NAME,
        params: {
          patientId: patientId.value,
          patientName: name.value,
        }
      });
    }

    onMounted(async () => {
      eventBus.emit('v-drawer-active-item', MENU_CODE.PATIENTS);

      if (patientId.value) {
        dateFrom.value.setHours(0, 0, 0, 0);
        dateTo.value.setHours(0, 0, 0, 0);
        await loadPatient();
        await loadVitalData(true);
      }
    })

    return {
      patientId,
      showDialogError,
      showDialogConfirmation,
      errorMessage,
      menuBirthday,
      menuFrom,
      menuTo,
      dateBirthday,
      dateFrom,
      dateTo,
      name,
      birthday,
      genre,
      gender,
      phone,
      mobile,
      height,
      weight,
      email,
      syncFlag,
      minSpO2,
      minHeartRate,
      maxHeartRate,
      chartOptions,
      series,
      formattedDateBirthday,
      formattedDateFrom,
      formattedDateTo,
      onClickBack,
      updateDateFrom,
      savePatient,
      deletePatient,
      shareLinkPatient,
      loadVitalData,
    }
  }
}

</script>

<style lang="scss" scoped>
.title-txt {
  font-family: var(--font-secondary);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--color-primary-dark);
}

.detail-txt {
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: var(--color-black);
}

.subtitle-txt {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: 700;
}

.report-container {
  background: #EBEFF6;
}

.base-btn {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;

  &-create {
    color: var(--color-white) !important;
    background-color: var(--color-primary-dark) !important;
    border: 1px solid var(--color-primary-dark) !important;
  }

  &-delete {
    color: var(--color-white) !important;
    background-color: var(--color-red) !important;
    border: 1px solid var(--color-red) !important;
  }

  &-edit {
    color: var(--color-white) !important;
    background-color: var(--color-orange) !important;
    border: 1px solid var(--color-orange) !important;
  }

  &-share {
    color: var(--color-white) !important;
    background-color: var(--color-primary) !important;
    border: 1px solid var(--color-primary) !important;
  }
}

.loading-text {
  color: white;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
